

import React, { useState } from 'react';
import './NavBar.css';
import { Link } from 'react-router-dom';

function NavBar() {
    const [isResponsive, setIsResponsive] = useState(false);

    const toggleMenu = () => {
        setIsResponsive(!isResponsive);
    };

    return (
        <div className={`navbar flex justify-content-space-between bg-navbar plr-10 ptb-20 ${isResponsive ? 'responsive' : ''}`}>
            <div className='nav-logo'>
                <b className='alt-text'>Raju</b>
            </div>
            <div className='icon' onClick={toggleMenu}>
                &#9776; {/* Unicode for hamburger menu icon */}
            </div>
            <div className='flex'>
                <div className='pl-5 nav-links'>
                    <Link className='link' to="/">Home</Link>
                </div>
                <div className='pl-5 nav-links'>
                    <Link className='link' to="/about">About</Link>
                </div>
                <div className='pl-5 nav-links'>
                    <Link className='link' to="/education">Education</Link>
                </div>
                <div className='pl-5 nav-links'>
                    <Link className='link' to="/contact">Contact</Link>
                </div>
                <div className='pl-5 nav-links'>
                    <Link className='link' to="/services">Services</Link>
                </div>
            </div>
            
        </div>
    );
}

export default NavBar;
