import React from "react";
import { NavLink } from "react-router-dom";

  
  function Services(){
    return (
   
        <div>
        <footer className="footer text-white">
          <div className="container">
            <footer className="py-5">
              <div className="row">
                 <div className="col-3">
                  <h4>FINE ART</h4>
                </div> 

                <div className="col-4 offset-1">
                  <form>
                    <h5>Contact me for making <b><b>Website</b> </b></h5>
                    <p>Managing technical areas like web development, app development, graphic design, etc.</p>
                    <div className="d-flex w-100 gap-2">
                      
                    <b>Mob - 9608669902</b>
                    <b>Email - projectsolvingman.com</b>
                    
                     
                    </div>
                  </form>
                </div>
              </div>
  
              <div className="d-flex justify-content-between pt-4 mt-4 border-top">
                <p>© 2024 Raju, Inc. All rights reserved.</p>
                <ul className="list-unstyled d-flex">
                  <li className="ms-3">
                    <NavLink className="link-light" to="https://www.facebook.com/profile.php?id=100040132956725">
                      <i className="fa fa-facebook fa-2x"></i>
                    </NavLink>
                  </li>
                  <li className="ms-3">
                    <NavLink className="link-light" to="https://www.instagram.com/raju_mourya_123?igsh=MWp2em1paWE3c3J0ZQ==">
                      <i className="fa fa-instagram fa-2x"></i>
                    </NavLink>
                  </li>
                  <li className="ms-3">
                    <NavLink className="link-light" to="https://www.linkedin.com/in/raju-kumar-219311297">
                      <i className="fa fa-linkedin fa-2x"></i>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </footer>
          </div>
        </footer>
      </div>
    
        
 )
}
export  default Services