import { NavLink } from 'react-router-dom';
import './Home.css'
import React from "react";

  

function About(){
  
    return (

        <div>
        <section id="about" className="pb-5">
            <div className="container my-5 py-5">
                <div className="row">
                    <div className="col-md-6">
                        <img src="/Raju.jpg" alt="About" className="w-75 mt-5" />
                    </div>
                    <div className="col-md-6">
                        <h3 className="fs-5 mb-0">About Us</h3>
                        <h1 className="display-6 mb-2">Who <b>I</b> Am</h1>
                        <hr className="w-50"/>
                        <p className="lead mb-4">A web developer specializes in creating and maintaining websites. They work with languages like HTML, CSS, JavaScript, and frameworks like React, Angular, or Vue.js for front-end development. For back-end development, they use Node.js, Express.js, and databases like MongoDB or SQL. Web developers ensure websites
                         are responsive, user-friendly, and function smoothly across
                          different devices. They often collaborate with designers to integrate visual elements and with other developers for seamless functionality. Additionally, web developers optimize website performance, implement security measures, and may handle server management and deployment processes.</p>
                        <NavLink to="/contact" className="btn btn-primary rounded-pill px-4 py-2">Get Started</NavLink> 
                    <NavLink to="/contact" className= "btn btn-outline-primary rounded-pill px-4 py-2 ms-2">Contact Us</NavLink>
                    </div>
                </div>
            </div>
        </section>
    </div>

 )
}
export  default About