import './Education.css'


function Education (){
    return (
       <>
    
         <section className="education" id="education">
            <h2 className="heading">My <span>Journey
            <hr className="w-100 "/> </span>   </h2>
           
            <div className="education-row">
              <div className="education-column">
                  <h3 className="title">Projects</h3>
                  <div className="education-box">
      
                      <div className="education-content">
                          <div className="content">
                              <div className="year"><i className='fa fa-calendar fa-2x '></i>
                                 
                                  08 - 2023
                                  
                              </div>
                              <h3>Portfolio </h3>
                              <p>Using HTML create a page ,CSS make design ,JavaScript make some back-end part and front-end part.
                                <br/>
                                <a href=" https://raju-mourya.github.io/mecode/">https://raju-mourya.github.io/mecode/</a> </p>
      
                              
                          </div>
                      </div>
                      
                      <div className="education-content">
                          <div className="content">
                              <div className="year"><i className='fa fa-calendar fa-2x'></i>
                                  02 - 2024 
                                
                              </div>
                              <h3>Enviromental Website </h3>
                              <p> I am Preparing Interview Make Enviromental Website for Green Club. 
                                <a href="https://raju-mourya.github.io/Nature/">https://raju-mourya.github.io/Nature/</a>
                              </p>
                              
                              
                          </div>
                      </div>
                     
                      <div className="education-content">
                          <div className="content">
                              <div className="year"><i className='fa fa-calendar fa-2x'></i>2022 - 2023</div>
                              <h3>JEE Prepration </h3>
                              <p>Coaching : The DOT  (6L Model Town Rewari ,Hariyana ) and The DOT best institute for jee prepration (https://thedotiit.in/).</p>
                              
                              
                          </div>
                      </div>
                       
                      <div className="education-content">
                          <div className="content">
                              <div className="year"><i className='fa fa-calendar fa-2x'></i>2023 - 2027</div>
                              <h3>Engineering Degree - NIT Allahabad </h3>
                              <p>College : Motilal Neharu National institute Of Technology, Allahabad ,Uttar pradesh and Currently ,I am first year student in this college.</p>
                              
                              
                          </div>
                      </div>
                  </div>
              </div>
                
      
              <div className="education-column">
                  <h3 className="title">Exprience</h3>
                  <div className="education-box">
      
                      <div className="education-content">
                          <div className="content">
                              <div className="year"><i className='fa fa-calendar fa-2x'></i>2022 - 2023</div>
                              <h3>Web Developer  </h3>
                              <p>I am Frontend Developer  ,Using  HTML ,CSS ,JavaScript done many project and upload on GitHub .Also Knowledge about GitHub.  </p>
      
                              
                          </div>
                      </div>
                      
                      <div className="education-content">
                          <div className="content">
                              <div className="year"><i className='fa fa-calendar fa-2x'></i>2022 - 2023</div>
                              <h3>Microsoft Excel  </h3>
                              <p> Microsoft Excel is a program in the Office 365 suite typically used to store, sort and manipulate data .Using M.S. Excel analysing data .</p>
                              
                              
                          </div>
                      </div>
                     
                      <div className="education-content">
                          <div className="content">
                              <div className="year"><i className='fa fa-calendar fa-2x'></i>2022 - 2023</div>
                              <h3>Power Point  </h3>
                              <p>Using PowerPoint Presentations to Present easy way. This course learn in first semester.</p>
                              
                              
                          </div>
                      </div>
                       
                      <div className="education-content">
                          <div className="content">
                              <div className="year"><i className='fa fa-calendar fa-2x'></i>2022 - 2023</div>
                              <h3>Graphics design  </h3>
                              <p>Graphics design Mean combine text and photos together and makes message  look more effective.</p>
                              
                              
                          </div>
                      </div>
                  </div>
              </div>
            </div>
          </section>
      
     
{/*     
     <section className="skills" id="skills">
        <h2 className="heading">My <span>skills</span></h2>
  
        <div className="skills-row">
           <div className="skills-column">
             <h3 className="title">Coding Skills</h3>
  
             <div className="skills-box">
            <div className="skills-content">
              <div className="progress">
                  <h3>HTML <span>90%</span></h3>
                  <div className="bar"><span></span></div>
              </div>
              
              <div className="progress">
                  <h3>CSS <span>70%</span></h3>
                  <div className="bar"><span></span></div>
              </div>
  
              <div className="progress">
                  <h3>JavaScript <span>65%</span></h3>
                  <div className="bar"><span></span></div>
              </div>
  
              <div className="progress">
                  <h3>C++ <span>60%</span></h3>
                  <div className="bar"><span></span></div>
              </div>
  
            </div>
  
             </div>
  
  
  
           </div>
  
           <div className="skills-column">
              <h3 className="title">Professional Skills</h3>
   
              <div className="skills-box">
             <div className="skills-content">
               <div className="progress">
                   <h3>PowerPoint <span>90%</span></h3>
                   <div className="bar"><span></span></div>
               </div>
               
               <div className="progress">
                   <h3>Web Development <span>60%</span></h3>
                   <div className="bar"><span></span></div>
               </div>
   
               <div className="progress">
                   <h3>Graphics design <span>35%</span></h3>
                   <div className="bar"><span></span></div>
               </div>
   
               <div className="progress">
                   <h3>Excell <span>60%</span></h3>
                   <div className="bar"><span></span></div>
               </div>
             </div>
              </div>
            </div>
        </div>
       </section> */}
  
  
       
       </>
                

 )
}
export  default Education