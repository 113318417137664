import './Home.css'
function Services(){
    return (
     
<div>
  
      <section id="service">
      
        <div className="container my-5 py-5">
          <div className="row">
            <div className="col-12">
              <h3 className="fs-5 text-center mb-0">Our Services</h3>
              <h1 className="display-6 text-center mb-4">
                Our <b>Awesome</b> Services
              </h1>
             <hr className=" w-25 mx-auto " />
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-4">
              <div className="card p-3 ">
                <div className="card-body text-center">
                    <i className="fa fa-cogs fa-4x mb-4 text-primary"></i>
                  <h5 className="card-title mb-3 fs-4 fw-bold" >Highly Customizable</h5>
                  <p className="card-text lead">
                  Create a customizable website by planning, choosing a platform, designing,
                   adding content, testing, launching, and maintaining. Use plugins and CSS.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card p-3 ">
                <div className="card-body text-center">
                    <i className="fa fa-mobile fa-4x mb-4 text-primary"></i>
                  <h5 className="card-title mb-3 fs-4 fw-bold">Fully Responsive Layout</h5>
                  <p className="card-text lead">
                  Create a fully responsive website by planning, choosing a platform,
                   designing, adding content, testing, launching, and maintaining. 
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card p-3 ">
                <div className="card-body text-center">
                    <i className="fa fa-users fa-4x mb-4 text-primary"></i>
                  <h5 className="card-title mb-3 fs-4 fw-bold">Users Experience</h5>
                  <p className="card-text lead">
                  Create a website focusing on user experience with responsive design, intuitive navigation, fast load times, 
                  accessible content, and regular testing and maintenance.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-4">
              <div className="card p-3 ">
                <div className="card-body text-center">
                    <i className="fa fa-laptop fa-4x mb-4 text-primary"></i>
                  <h5 className="card-title mb-3 fs-4 fw-bold">Creative Web Design</h5>
                  <p className="card-text lead">
                  Create a web design by planning layout,
                   choosing colors, fonts, and images, ensuring responsiveness, and testing across devices for consistency.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card p-3 ">
                <div className="card-body text-center">
                    <i className="fa fa-file-code-o fa-4x mb-4 text-primary"></i>
                  <h5 className="card-title mb-3 fs-4 fw-bold">Unique and Clean</h5>
                  <p className="card-text lead">
                  Create a unique, clean web design with minimalistic layouts, distinct colors/fonts, optimized content,
                   creative navigation, and responsive design for all devices.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card p-3 ">
                <div className="card-body text-center">
                    <i className="fa fa-star-half-o fa-4x mb-4 text-primary"></i>
                  <h5 className="card-title mb-3 fs-4 fw-bold">Creative Ideas</h5>
                  <p className="card-text lead">
                  Explore interactive  dynamic backgrounds,animations, personalization, scroll effects, VUI, data visualization,
                   and social integration for creative web design.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

 )
}
export  default Services