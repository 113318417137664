
import './App.css';
import NavBar from  './components/NavBar';
import {BrowserRouter,Routes,Route } from "react-router-dom";
import Home from './components/Home'
import About from './components/About'
import Education from './components/Education'
import Contact from './components/Contact'
import Services from './components/Services'
import Footer from './components/Footer'



  function App() {
    
  return (
    <>
     <BrowserRouter>
     <NavBar/> 
     
     <Routes>
      <Route path='/' element={<Home/>} />
      <Route path='/about' element={<About/>} />
      <Route path='/education' element={<Education/>} />
      <Route path='/contact' element={<Contact/>} />
      <Route path='/services' element={<Services/>} />
      

     </Routes>
     <Footer/>
     
     </BrowserRouter>

    </>
  );
}

export default App;


