import './Home.css'
import React from 'react';

import { NavLink } from 'react-router-dom';

import Slider from 'react-slick';
import CustomNextArrow from './CustomNextArrow';
import CustomPrevArrow from './CustomPrevArrow';
import backgroundImage from './home.jpg';



function Home(){
    const sectionStyle = {
        
        backgroundImage: `url(${backgroundImage}) `,
        backgroundSize: 'cover',
        opacity: 1.1,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '100vh', // Full viewport height
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white' // Text color for better visibility
      };
    

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
    };


    return (
        <div>

<div   style={sectionStyle} class="home" id="home">
    
        <div class="home-content">
            <h1>Hi, I'm <span>Raju </span></h1>
            <div class="text-animate">
            <h3>Welcome to the Portfolio Website</h3>
           </div> 
          
           </div>
          
           </div>
        
        


<div className="App">

    </div>
           
            
          <br/>
            <hr/>
            <div className="container">
					
                    <div className="card">
                        <div className="imgBx">
                            <a href="#">
                            <img src="/app.jpeg"/>
                            </a>
                        
                            <h2>App Developer  </h2>
                            <p><br/>App development involves designing, coding,
                             and deploying software applications for mobile or web platforms, focusing on user experience, functionality, and performance using various programming languages and frameworks.
                            </p>
                            
                        </div>
                    </div>


                    <br/>
                  
                    <div className="card">
                        <div className="imgBx">
                            <a href="#">
                            <img src="/web D.jpeg"/>
                            </a>
                            <h2>Web developer </h2>
                            <p><br/>Web development entails creating and maintaining websites, involving front-end design,
                             back-end coding, and database management to ensure functionality, user experience, and performance across different devices and browsers.





. 
                            </p>					
        
                        </div>	
                    </div>	

                    <br/>
                    
                    <div className="card">
                        <div className="imgBx">
                            <a href="#">
                            <img src="/Graphics.jpg"/>
                            </a>
                            <h2>Graphics designer </h2>
                            <p><br/>Graphic design combines visual elements like typography, images, and colors to create engaging and effective visual communications for branding, advertising, and digital media, 
                            enhancing aesthetic appeal and user experience.
                            </p>
                        </div>	
                    </div>	
                    
                </div>



                <div>
            <section id="home">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 mt1-5">
                            <h1 className="display-4 fw-bolder mb-4 text-center text-white">Introduction</h1>
                            <p className="lead text-center fs-4 mb-5 text-white">I am pursuing B.tech 2nd year , Mechanical Engineering department in NIT Allahabad. I have more interest in Coding
            and problem solving related to coding.I have Knowledge about HTML, CSS, JavaScript ,C++ ,Python . Using HTML create a page ,CSS make design ,JavaScript make  back-end part and front-end part.</p>
                            <div className="buttons d-flex justify-content-center">
                                <NavLink to="/contact" className="btn btn-light me-4 rounded-pill px-4 py-2">Get Quote</NavLink>
                                <NavLink to="/services" className="btn btn-outline-light rounded-pill px-4 py-2">Our Services</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            


        </div>

        </div>
          

        
          





        


 )
}
export  default Home